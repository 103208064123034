import "./App.css";
import LandingPage from "./components/landingPage/LandingPage";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Wobble - Több, mint egy időpontfoglaló</title>
        <meta
          name="description"
          content="ScheduleApp helps you manage your time efficiently with an easy-to-use interface and powerful features. Stay organized and focused with our scheduling app."
        />
        <meta
          name="keywords"
          content="scheduling, calendar, time management, reminders, task management"
        />
        <link rel="canonical" href="https://www.scheduleapp.com/" />
        <meta
          property="og:title"
          content="ScheduleApp - Your Ultimate Scheduling Solution"
        />
        <meta
          property="og:description"
          content="ScheduleApp helps you manage your time efficiently with an easy-to-use interface and powerful features. Stay organized and focused with our scheduling app."
        />
        <meta
          property="og:image"
          content="https://www.scheduleapp.com/images/og-image.jpg"
        />
        <meta property="og:url" content="https://www.scheduleapp.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <LandingPage />
    </HelmetProvider>
  );
}

export default App;
