import { Box, Flex, Link, Text,  } from "@chakra-ui/react";
import { greyColor, mainColor, darkColor } from "./LandingPage";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import IframeComponent from "./Iframe";

const MainSection = () => {
  return (
    <Box>
      <Flex
        direction="column"
        align="center"
        justify="center"
        bg={greyColor}
        color="white"
        height="80vh"
        clipPath="polygon(0 0, 100% 0, 100% 75%, 0 100%)"
        textAlign="center"
        px={8}
        py={{ base: 24, md: 24 }}
        backgroundImage="radial-gradient(rgba(0, 113, 136, 0.2) 1.85px, rgba(250, 250, 250, 0.2) 1.85px)"
        backgroundSize="37px 37px"
      >
        <IframeComponent 
          src="https://cdn.millerproductions.hu/wobble-text"
          height="20vh"
          title="slogen"
        />
        <Text fontSize={{ base: "lg", md: "2xl" }} mt={4}>
          Időpontfoglaló, weblap, és admin felület egy helyen
        </Text>
        <Link href="https://app.wobble.hu/signup_page" isExternal>
          <Box
            as="button"
            height="40px"
            w="200px"
            lineHeight="1.2"
            transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
            border="1px"
            px="8px"
            mt={8}
            borderRadius="5px"
            fontSize="16px"
            fontWeight="semibold"
            bg={mainColor}
            borderColor={mainColor}
            color="white"
            _hover={{ bg: darkColor }}
            _active={{
              bg: darkColor,
              transform: "scale(0.98)",
              borderColor: darkColor,
            }}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
            }}
          >
            Megnézem 
            <ArrowForwardIcon />
          </Box>
        </Link>
      </Flex>
    </Box>
  );
};

export default MainSection;


