import { FC, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Container,
  Heading,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import mockup_doctor from "../../assets/images/mockup_docktor.png";
import mockup_beauty from "../../assets/images/mockup_beauty.png";
import mockup_gym from "../../assets/images/mockup_gym.png";
import mockup_photographer from "../../assets/images/mockup_photographer.png";
import mockup_teacher from "../../assets/images/mockup_teacher.png";
import mockup_coach from "../../assets/images/mockup_coach.png";
import { darkColor } from "./LandingPage";

interface SectionProps {
  reverse: boolean;
  imageSrc: string;
  text: string;
  bgColor?: string;
  bgGradient?: string;
}

const Section: FC<SectionProps> = ({
  reverse,
  imageSrc,
  text,
  bgColor,
  bgGradient,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (src: string) => {
    setSelectedImage(src);
    onOpen();
  };

  return (
    <>
      <Box bg={bgColor} py={10} bgGradient={bgGradient}>
        <Container maxW="container.xl">
          <Flex
            direction={["column", "column", reverse ? "row-reverse" : "row"]}
            align="center"
            justify="space-between"
          >
            <Box flex="1" p={5}>
              <Image
                src={imageSrc}
                alt="section image"
                width="100%"
                height="auto"
                objectFit="contain"
                borderRadius="md"
                mx="auto"
                _hover={{ cursor: "pointer", transform: "scale(1.05)" }}
                onClick={() => handleImageClick(imageSrc)}
                transition="transform 0.3s"
              />
            </Box>
            <Box flex="1" p={5}>
              <Text fontSize={["lg", "xl", "2xl"]}>{text}</Text>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW="90vw"
          maxH="90vh"
          bg="transparent"
          boxShadow="none"
          position="relative"
        >
          <ModalCloseButton
            position="absolute"
            top="10px"
            right="10px"
            size="lg"
            color="white"
            bg="blackAlpha.700"
            _hover={{ bg: "blackAlpha.900" }}
          />
          <ModalBody
            p={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={selectedImage || ""}
              maxH="90vh"
              maxW="90vw"
              objectFit="contain"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const UsageSections: FC = () => {
  return (
    <Box id="usage">
      <Center bgGradient="linear(to-l, gray.50, gray.500)">
        <Heading color={darkColor} h={40} pt={20}>
          Kiknek ajánljuk?
        </Heading>
      </Center>
      <Section
        imageSrc={mockup_doctor}
        text="Fogorvosoknak, orvosoknak, pszichológusoknak"
        reverse={false}
        // bgColor="gray.100"
        bgGradient="linear(to-l, gray.50, gray.500)"
      />
      <Section
        imageSrc={mockup_beauty}
        text="Kozmetikusoknak, masszőröknek, fodrászoknak, manikűrösöknek, pedikűrösöknek, sminkeseknek"
        reverse={true}
        // bgColor="pink.100"
        bgGradient="linear(to-r, pink.50, pink.500)"
      />
      <Section
        imageSrc={mockup_gym}
        text="Edzőknek, trénereknek"
        reverse={false}
        // bgColor="green.100"
        bgGradient="linear(to-l, green.100, green.500)"
      />
      <Section
        imageSrc={mockup_photographer}
        text="Fotósoknak, videósoknak"
        reverse={true}
        // bgColor="red.100"
        bgGradient="linear(to-r, red.400, gray.400)"
      />
      <Section
        imageSrc={mockup_teacher}
        text="Magántanároknak"
        reverse={false}
        // bgColor="teal.100"
        bgGradient="linear(to-l, teal.500, green.100)"
      />
      <Section
        imageSrc={mockup_coach}
        text="Tanácsadóknak, konzultációt tartóknak, coachoknak"
        reverse={true}
        bgColor="blue.100"
        bgGradient="linear(to-r, blue.50, blue.500)"
      />
      <Center
        bgGradient="linear(to-r, blue.50, blue.500)"
        p={{ base: 8, md: 10 }}
      >
        <Text
          fontSize={{ md: 24 }}
          color={darkColor}
          h={40}
          pt={{ base: 8, md: 10 }}
        >
          Tehát mindazok számára, akik ügyfelekkel dolgoznak, és lényeges
          számukra a folyamatos elérhetőség, az ügyfelekkel való
          kapcsolattartás, valamint a foglalások kezelése.
        </Text>
      </Center>
    </Box>
  );
};

export default UsageSections;
