import { Box, Flex, Text } from "@chakra-ui/react";
import { mainColor } from "./LandingPage";

const AboutUs = () => {
  return (
    <Box id="about" p={8} bg="gray.100" h={500} alignContent="center">
      <Flex direction="column" align="center">
        <Text fontSize="4xl" fontWeight="bold" color={mainColor} mb={4}>
          Rólunk
        </Text>
        <Text fontSize="lg" color="gray.600" maxW="3xl" textAlign="center">
          Ismerjük azt az érzést, amikor mindenre figyelnél, de valami mindig
          kicsúszik a kezedből. Azért vagyunk itt, hogy az online jelenlétedet
          megbízhatóvá és profivá tegyük, így te nyugodtan koncentrálhatsz a
          vállalkozásod vezetésére.{" "}
        </Text>
      </Flex>
    </Box>
  );
};

export default AboutUs;
