import React, { useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import mockup_calendar_google from "../../assets/images/mockup_google_calendar_1.png";
import mockup_calendar from "../../assets/images/mockup_google_calendar_2.png";

interface ImageComparisonSliderProps {
  image1: string;
  image2: string;
  width?: string | string[];
  height?: string | string[];
}

const ImageComparisonSlider: React.FC<ImageComparisonSliderProps> = ({
  image1,
  image2,
  width = "100%",
  height = "400px",
}) => {
  const [sliderPosition, setSliderPosition] = useState<number>(50);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const newSliderPosition =
      ((e.clientX - boundingRect.left) / boundingRect.width) * 100;
    setSliderPosition(Math.max(0, Math.min(100, newSliderPosition)));
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const touch = e.touches[0];
    const newSliderPosition =
      ((touch.clientX - boundingRect.left) / boundingRect.width) * 100;
    setSliderPosition(Math.max(0, Math.min(100, newSliderPosition)));
  };

  return (
    <Box
      position="relative"
      width={width}
      height={height}
      maxW="100%"
      overflow="hidden"
      onMouseMove={handleMouseMove}
      onTouchMove={handleTouchMove}
      cursor="ew-resize"
    >
      <Image
        src={image2}
        alt="Image 2"
        objectFit="cover"
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        zIndex="1"
      />

      <Box
        position="absolute"
        top="0"
        left="0"
        height="100%"
        width="100%"
        zIndex="2"
      >
        <Image
          src={image1}
          alt="Image 1"
          objectFit="cover"
          width="100%"
          height="100%"
          clipPath={`inset(0 ${100 - sliderPosition}% 0 0)`}
        />
      </Box>

      <Box
        position="absolute"
        top="0"
        left={`${sliderPosition}%`}
        transform="translateX(-50%)"
        width="4px"
        height="90%"
        backgroundColor="gray.100"
        zIndex="3"
      />
    </Box>
  );
};

const SwipeableImage = () => {
  return (
    
    <Box p={5} display="flex" justifyContent="center" alignItems="center">
      <ImageComparisonSlider
        image1={mockup_calendar}
        image2={mockup_calendar_google}
        // width={["80%", "80%", "70%", "60%"]}
        height={["180px", "300px", "400px", "500px"]}
      />
    </Box>
  );
};

export default SwipeableImage;
