import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Text,
  Center,
  List,
  ListIcon,
  ListItem,
  Highlight,
  Heading,
} from "@chakra-ui/react";

const DescriptionSection = () => {
  return (
    <Box
      id="features"
      p={8}
      bg="gray.100"
      bgGradient="linear(to-t, gray.50, gray.300)"
    >
      <Flex direction="column" align="center">
        <Box
          position="relative"
          backgroundSize="cover"
          backgroundPosition="center"
          zIndex={2}
        >
          <Center>
            <Heading color="#024856" as="h4" mb={4} textAlign="center">
              Fejlett foglalási funkciók, hogy vállalkozását autopilóta
              üzemmódba kapcsolhassa
            </Heading>
          </Center>
          <Box p={{ base: 4, md: 18, lg: 28 }}>
            <Text
              mt={4}
              color="#024856"
              align="center"
              pb={8}
              fontSize={{ base: "12px", md: "14px", lg: "24px" }}
            >
              A Wobble online időpontfoglaló szoftverével búcsúzhat a toll-papír
              használattól, időt takaríthat meg, csökkentheti a lemondásokat és
              növelheti a dolgozói termelékenységet.
            </Text>
            <Text fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
              Ügyfelekkel dolgozol és szükséged van rugalmas időpontfoglalási
              felületre a munkádhoz, hogy{" "}
              <Highlight
                query={[
                  "ne kelljen félbehagynod",
                  "csörög a telefon vagy érkezett egy üzenet?",
                ]}
                styles={{
                  px: "2",
                  py: "1",
                  rounded: "full",
                  bg: "yellow.200",
                }}
              >
                ne kelljen félbehagynod a tennivalót csak azért, mert csörög a
                telefon vagy érkezett egy üzenet?
              </Highlight>
            </Text>
            <Text fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
              A Wobble erre egy nagyon egyszerű időpontfoglalási felületet
              kínál, hogy csak a munkára kelljen koncentrálni.
            </Text>{" "}
            <Text fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
              Nincs csomag dömping, vagy ingyenes, ami semmit nem tud, sem drága
              csomag minden felesleges tartalommal. Csak a lényeg!{" "}
              <Highlight
                query={"Gyere és próbáld ki ingyen 14 napig!"}
                styles={{ px: "2", py: "1", rounded: "full", bg: "teal.100" }}
              >
                Gyere és próbáld ki ingyen 14 napig!
              </Highlight>
            </Text>
          </Box>
          <Box>
            <Center>
              <List spacing={3}>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Korlátlan szolgáltatás létrehozása
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Korlátlan időpont létrehozása
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Email értesítő a foglalásokról
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Emlékeztető email
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Google calendar kapcsolat
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Egyedi Google Meet link
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Ügyfélkezelés
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Saját domain használata
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Személyre szabható URL link
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Egyedi foglalási felület
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Vállalkozás/szolgáltatások bemutatása
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Blog poszt készítés
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Időzített vagy azonnali email küldési lehetőség
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Referencia képek megjelenítése
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Értékelési felület
                </ListItem>
                <ListItem fontSize={{ base: "14px", md: "14px", lg: "28px" }}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  Ingyenes SSL tanúsítvány
                </ListItem>
              </List>
            </Center>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default DescriptionSection;
