import {
  Box,
  Flex,
  Button,
  VStack,
  HStack,
  IconButton,
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import { mainColor } from "./LandingPage";
import logo_white from "../../assets/logo/logo_white.png";
import logo from "../../assets/logo/logo_wobble.png";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        as="header"
        bg={mainColor}
        color="white"
        py={4}
        px={8}
        justify="space-between"
        align="center"
        position="sticky"
        top="0"
        zIndex="1000"
        w="100%"
      >
        <Box h={34} w={140} objectFit="cover">
          <Image src={logo_white} alt="logo" />
        </Box>
        <HStack
          spacing={{ base: 4, md: 8 }}
          display={{ base: "none", md: "flex" }}
        >
          <ChakraLink href="#integration" _hover={{ textDecoration: "none" }}>
            Hogyan működik
          </ChakraLink>
          <ChakraLink href="#features" _hover={{ textDecoration: "none" }}>
            Funkciók
          </ChakraLink>
          <ChakraLink href="#usage" _hover={{ textDecoration: "none" }}>
            Kiknek?
          </ChakraLink>
          <ChakraLink href="#pricing" _hover={{ textDecoration: "none" }}>
            Árak
          </ChakraLink>
          <ChakraLink href="#about" _hover={{ textDecoration: "none" }}>
            Rólunk
          </ChakraLink>
        </HStack>
        <HStack spacing={4} display={{ base: "none", md: "flex" }}>
          <Button
            colorScheme="white"
            variant="link"
            as="a"
            href="https://app.wobble.hu/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Belépés
          </Button>
          <Button
            colorScheme="white"
            variant="outline"
            as="a"
            href="https://app.wobble.hu/signup_page"
            target="_blank"
            rel="noopener noreferrer"
          >
            Regisztráció
          </Button>
        </HStack>
        <IconButton
          aria-label="Open Menu"
          icon={<FaBars />}
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          colorScheme="white"
        />
      </Flex>
      {/* Mobile Drawer */}
      <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Box h={34} w={140} objectFit="cover">
                <Image src={logo} alt="logo" />
              </Box>
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="start">
                <ChakraLink
                  color={mainColor}
                  as="b"
                  href="#integration"
                  onClick={onClose}
                >
                  Hogyan működik
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  as="b"
                  href="#features"
                  onClick={onClose}
                >
                  Funkciók
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  as="b"
                  href="#usage"
                  onClick={onClose}
                >
                  Kiknek?
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  as="b"
                  href="#pricing"
                  onClick={onClose}
                >
                  Árak
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  as="b"
                  href="#about"
                  onClick={onClose}
                >
                  Rólunk
                </ChakraLink>
              </VStack>
            </DrawerBody>
            <DrawerFooter justifyContent="center">
              <Button
                as="a"
                href="https://app.wobble.hu/login"
                target="_blank"
                rel="noopener noreferrer"
                colorScheme="teal"
                width="200px"
                variant="outline"
                onClick={onClose}
                m={8}
              >
                Belépés
              </Button>
              <Button
                as="a"
                href="https://app.wobble.hu/signup_page"
                target="_blank"
                rel="noopener noreferrer"
                width="200px"
                colorScheme="teal"
                variant="outline"
                onClick={onClose}
                m={8}
              >
                Regisztráció
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default Navbar;
