import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    brand: {
      1: "#007188",
      2: "#024856",
      3: "#5AB3C5",
    },
  },
  styles: {
    global: {
      body: {
        bg: 'white',
        color: 'black',
      },
      // styles for the `a`
      a: {
        color: 'white',
        _hover: {
          textDecoration: 'underline',
        },
      },
      p: {
        color: "#024856",
      },
      button: {
        color: 'white'
      },
    },
  },
})

export default theme