import { Box } from "@chakra-ui/react";

import TreeStepsSection from "./TreeStepsSection";
import MainSection from "./MainSection";
import CalendarPartSection from "./CalendarPartSection";
import FeaturesSection from "./FeaturesSection";
import PriceSection from "./PriceSection";
import AboutUs from "./AboutUs";
import DescriptionSection from "./DescriptionSection";
import Footer from "./Footer";
import Navbar from "./Navbar";
import UsageSections from "./Sections";

export const mainColor = "#007188";
export const darkColor = "#024856";
export const lightColor = "#5AB3C5";
export const greyColor = "#E9F0F1";

const LandingPage = () => {
  return (
    <Box bg="gray.50" color="gray.800" scrollBehavior="smooth">
      {/* Header */}
      <Navbar />

      {/* Hero Section */}

      <MainSection />

      {/* Dynamic Calendar Image Section */}
      <CalendarPartSection />

      {/* Features Section */}
      <FeaturesSection />

      {/* 3 steps description section */}
      <TreeStepsSection />

      {/* Integration Section */}
      <DescriptionSection />

      {/* For whose Section */}
      <UsageSections />
      {/* Pricing Section */}
      <PriceSection />

      {/* About Section */}
      <AboutUs />

      {/* Testimonials Section */}
      {/* <Box id="testimonials" p={8} bg="white">
          <Flex direction="column" align="center">
            <Text fontSize="4xl" fontWeight="bold" color="blue.600" mb={4}>
              Testimonials
            </Text>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={8}
              align="center"
              justify="center"
              maxW="7xl"
              mx="auto"
            >
              <Box
                flex="1"
                p={4}
                textAlign="center"
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
              >
                <Text fontSize="lg" color="gray.600">
                  "ScheduleApp has transformed how I manage my tasks and
                  appointments. It's incredibly user-friendly!"
                </Text>
                <Text fontWeight="bold" mt={2}>
                  Jane Doe
                </Text>
              </Box>
              <Box
                flex="1"
                p={4}
                textAlign="center"
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
              >
                <Text fontSize="lg" color="gray.600">
                  "The integration with my existing calendar tools was seamless.
                  Highly recommend ScheduleApp!"
                </Text>
                <Text fontWeight="bold" mt={2}>
                  John Smith
                </Text>
              </Box>
            </Stack>
          </Flex>
        </Box> */}

      {/* CTA Section */}

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default LandingPage;
